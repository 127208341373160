<template>
  <mi-dialog
    :model-value="deleteModal"
    :custom-design-dialog="true"
    class="inspector-delete-dialog"
    no-backdrop-dismiss
  >
    <div>
      <div>
        <div class="inspector-delete-close-icon">
          <mi-btn
            icon="close"
            icon-type
            icon-size="21px"
            fab
            flat
            @click="handleCloseDeleteModal"
          ></mi-btn>
        </div>
        <h3 class="q-mt-none q-mb-lg inspector-delete-header"> Delete Inspection? </h3>
      </div>

      <!-- Subtitle -->
      <p class="q-mb-xl">
        This will delete inspection <strong> {{ rowInfo.name }} </strong> and no one will have access to it again.
      </p>
    </div>

    <div class="inspector-delete-wrapper">
      <!-- Cancel button -->
      <mi-btn flat @click="handleCloseDeleteModal"> Cancel </mi-btn>

      <!-- Submit button -->
      <mi-btn @click="handleDeleteModal"> Delete </mi-btn>
    </div>
  </mi-dialog>
</template>

<script>
  export default {
    name: 'DeleteInspection',
    props: {
      deleteModal: {
        type: Boolean,
        default: false
      },
      rowInfo: {
        type: Object,
        default: () => ({})
      },
      handleCloseDeleteModal: {
        type: Function,
        default: () => {}
      },
      handleDeleteModal: {
        type: Function,
        default: () => {}
      }
    }
  }
</script>

<style>
  .inspector-delete-dialog {
    .q-dialog__inner > .mi-card {
      width: 683px;
      max-width: 683px;
      min-width: 580px;
      min-height: 293px;
      padding: 16px 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  @media (max-width: $mi-responsive-large-width) {
    .inspector-delete-dialog {
      .q-dialog__inner > .mi-card {
        width: 683px;
        max-width: 683px;
      }
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .inspector-delete-dialog {
      .q-dialog__inner > .mi-card {
        width: 477px;
        max-width: 477px;
      }
    }
  }

  .inspector-delete-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 16px;
  }

  .inspector-delete-header {
    font-size: 28px;
    line-height: 42px;
    font-weight: 700;
  }

  .inspector-delete-close-icon {
    display: flex;
    justify-content: flex-end;
  }
</style>
