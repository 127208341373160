<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events -->
<template>
  <div class="inspector-filter-bar flex">
    <div class="filters-right">
      <mi-refresh-btn
        :is-loading="areInspectionsLoading"
        class="q-mr-md"
        icon-size="16px"
        @clicked-refresh-btn="handleRefreshBtnChange"
      ></mi-refresh-btn>
      <div class="inspector-filter-bar__divider"></div>
      <mi-btn
        flat
        class="inspector-filter-bar__mine-only q-ml-sm"
        :class="isMineOnlyFilterSelected ? 'inspector-filter-bar__mine-only--selected' : ''"
        @click="handleMineOnlyFilter"
      >
        Mine only
      </mi-btn>
    </div>
    <div>
      <mi-btn
        v-if="hasInspections"
        class="table-display q-ml-auto"
        dense
        flat
        tabindex="-1"
        @mouseover="handleHoover(true)"
        @mouseout="handleHoover(false)"
        @click="$emit('handle-open-dialog')"
      >
        <img
          v-if="!hoover"
          class="table-display__icon q-mr-sm"
          src="@/assets/images/table_display_filter_icon.svg"
          alt="table-display-filter-icon"
        />
        <img
          v-else
          class="table-display__icon q-mr-sm"
          src="@/assets/images/table_display_filter_icon_white.svg"
          alt="table-display-filter-icon"
        />
        Table Display
      </mi-btn>
    </div>
  </div>
</template>

<script>
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'

  export default {
    name: 'InspectorFiltersBar',
    components: { MiBtn },
    props: {
      hasInspections: {
        type: Boolean,
        required: true
      },
      hoover: {
        type: Boolean,
        required: true,
        default: false
      },
      handleHoover: {
        type: Function,
        required: true,
        default: () => {}
      },
      handleMineOnlyFilter: {
        type: Function,
        required: true,
        default: () => {}
      },
      handleRefreshBtnChange: {
        type: Function,
        required: true,
        default: () => {}
      },
      areInspectionsLoading: {
        type: Boolean,
        required: true,
        default: false
      },
      isMineOnlyFilterSelected: {
        type: Boolean,
        required: true,
        default: false
      }
    },
    emits: ['handle-open-dialog']
  }
  </script>

  <style lang="scss" scoped>
  .inspector-filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .filters-right {
      display: flex;
      align-items: center;
    }

    &__divider {
      border: 2px solid $mi-anthracite-50;
      height: 27px;
    }

    &__mine-only {
      font-size: 14px;

      &--selected {
        /* stylelint-disable declaration-no-important */
        background-color: $mi-anthracite-800 !important;
        color: #ffffff !important;
      }
    }

    .table-display {
      margin-left: auto;
      height: 32px;

      &--selected {
        background-color: $mi-anthracite-800;
      }

      &__icon {
        width: 12px;
        height: 12px;
      }
    }
  }
</style>
