import {
  pmvHttpClient,
  pmvHttpClientWithHeaders
} from '@/api/instances'
import { INSPECTION } from './endpoints'

export const getInspections = async (queryParams = '') => pmvHttpClient({
  url: INSPECTION,
  method: 'GET',
  params: new URLSearchParams(queryParams)
})

export const getInspectionsDepartments = async () => pmvHttpClient({
  url: `${ INSPECTION }/departments`,
  method: 'GET'
})

export const saveInspection = async (data = {}) => pmvHttpClient({
  url: INSPECTION,
  method: 'POST',
  data
})

export const exportInspection = async (inspectionId = '') => pmvHttpClientWithHeaders({
  url: `/inspections/${ inspectionId }/results`,
  method: 'GET',
  responseType: 'blob'
})

export const deleteInspection = async (inspectionId = '') => pmvHttpClient({
  url: `/inspections/${ inspectionId }`,
  method: 'DELETE'
})
