<template>
  <div v-if="isDrawerShown">
    <mi-drawer
      v-model="inspectorDrawer"
      overlay
      bordered
      persistent
      side="left"
      class="mi-drawer"
    >
      <div>
        <div class="drawer-wrapper-header">
          <h3 class="drawer-header">New Inspection</h3>
          <mi-btn
            icon-size="18px"
            icon="close"
            fab
            flat
            @click="handleHide"
          ></mi-btn>
        </div>
        <mi-separator class="separator"></mi-separator>
        <div class="drawer-description">
          <h4 class="drawer-description-header">Inspection type: All valid KVs list</h4>
          <span class="drawer-description-text">
            Get from CoRE all component variants valid for different combinations or specific choices.
          </span>
        </div>
        <mi-separator class="separator"></mi-separator>
      </div>
      <div class="drawer-content">
        <div class="form-wrapper">
          <mi-form
            class="inspector-form"
            @submit.prevent="onSubmit"
          >
            <div class="inputs-wrapper">
              <div class="form-input-wrapper flex">
                <form-drawer-label
                  header="Setup"
                  label="Select the product model and planning period for this project."
                  icon="settings"
                  :icon-size="14"
                ></form-drawer-label>
                <div class="job-setup-wrapper">
                  <mi-text-field
                    v-model="form.name"
                    label="Name"
                    :rules="folderNameRules"
                    placeholder="Give a name to the export"
                    outlined
                    required
                  ></mi-text-field>
                  <div class="job-setup-bottom-wrapper flex">
                    <mi-select
                      v-model="form.productModel"
                      :display-value="form.productModel?.businessName || 'Select Product Model'"
                      :options="productModels"
                      :rules="productModelValidationRules"
                      class="q-mb-sm select-pm-dropdown inspector-bottom-input"
                      label="Product Model"
                      option-label="businessName"
                      options-selected-class="text-accent"
                      outlined
                      hide-bottom-space
                    ></mi-select>
                    <mi-text-field
                      v-model="form.selectedPP"
                      :rules="planningPeriodRules"
                      class="inspector-bottom-input"
                      label="Planning Period"
                      mask="######"
                      placeholder="YYYYMM"
                      prefix="PP"
                      outlined
                    ></mi-text-field>
                  </div>
                </div>
              </div>
              <mi-separator class="separator middle-separator"></mi-separator>
              <div class="inputs-wrapper">
                <div class="form-input-wrapper flex row no-wrap q-mt-lg">
                  <form-drawer-label
                    header="Choices Combination"
                    label="Enter different combinations of choices to inspect."
                    :custom-icon="true"
                    :icon-size="14"
                  ></form-drawer-label>
                  <choices-combinations-container
                    :invalid-choices="invalidChoices"
                    @update:pre-conditions="handleUpdatePreconditions"
                  >
                  </choices-combinations-container>
                </div>
              </div>
            </div>
            <div class="confirm-btns-wrapper">
              <mi-btn flat @click="handleHide">
                Cancel
              </mi-btn>
              <mi-btn
                color="accent"
                type="submit"
                :loading="!!loading"
              >
                Get valid KVs
              </mi-btn>
            </div>
          </mi-form>
        </div>
      </div>
    </mi-drawer>
  </div>
  <mi-overlay class="overlay" :model-value="isDrawerShown"></mi-overlay>
</template>

<script>
  import { ref, onMounted } from 'vue'
  import { getProductModels } from '@/api/rest/product-model'
  import MiDrawer from '@/packages/@mi-library/MiDrawer/MiDrawer.vue'
  import MiTextField from '@/packages/@mi-library/MiTextField/MiTextField.vue'
  import { maxLength, regex, required } from '@/utils/validators'
  import { PM_TYPE } from '@/constants'
  import ChoicesCombinationsContainer from './choices-combinations/ChoicesCombinationsContainer.vue'
  import FormDrawerLabel from '../schedules/drawer/FormDrawerLabel.vue'
  import { saveInspection } from '../../api/rest/inspector'

  export default {
    name: 'InspectorDrawer',
    components: { FormDrawerLabel, ChoicesCombinationsContainer, MiTextField, MiDrawer },
    props: {
      isDrawerShown: {
        type: Boolean,
        default: false
      },
      handleHide: {
        type: Function,
        default: () => {}
      }
    },
    emits: ['refreshInspections'],
    setup(props, { emit }) {
      const inspectorDrawer = ref(true)
      const loading = ref(false)
      const form = ref({
        name: '',
        productModel: '',
        selectedPP: '',
        choicesCombinations: []
      })
      const productModels = ref([])
      const invalidChoices = ref(new Set([]))
      const folderNameRules = ref([
        required,
        maxLength(80, 'Max length of 80 characters reached')
      ])
      const productModelValidationRules = ref([required])
      const planningPeriodRules = ref([
        required,
        regex(
          /^(20[0-9][0-9]|2100)(0[1-9]|1[0-9]|2[0-9]|3[0-6])$/,
          'Enter a valid planning period'
        )
      ])

      const onSubmit = async () => {
        loading.value = true
        const { name, productModel, selectedPP, choicesCombinations } = form.value
        const inspection = {
          encodedBusinessName: productModel.encodedBusinessName,
          name,
          checkRange: {
            from: `PP${ selectedPP }`,
            to: `PP${ selectedPP }`
          },
          preconditions: choicesCombinations.map((combination, index) => ({
            name: `Precondition ${ index + 1 }`,
            choices: combination.preCondition
          }))
        }
        try {
          await saveInspection(inspection)
          props.handleHide()
          emit('refreshInspections')
        }
        catch (error) {
          const { preconditions } = error.response.data
          preconditions?.forEach(invalidCombination => {
            const invalidChoicesIds = invalidCombination.invalidChoices.map(choice => choice.id2)
            invalidChoicesIds?.forEach(invalidChoiceId => {
              if (!invalidChoices.value.has(invalidChoiceId)) invalidChoices.value.add(invalidChoiceId)
            })
          })
        }
        finally {
          loading.value = false
        }
      }

      const getProductModelsSelectOptions = async () => {
        const pms = await getProductModels()
        productModels.value = pms.filter(pm => pm.productModelType === PM_TYPE.PRODUCT_MODEL) || []
      }

      const handleUpdatePreconditions = ({ choicesCombinations }) => {
        form.value.choicesCombinations = choicesCombinations
      }

      onMounted(async () => {
        await getProductModelsSelectOptions()
      })

      return {
        inspectorDrawer,
        form,
        folderNameRules,
        onSubmit,
        productModels,
        productModelValidationRules,
        planningPeriodRules,
        loading,
        invalidChoices,
        handleUpdatePreconditions
      }
    }
  }
</script>

<style lang="scss" scoped>
  ::v-deep(.q-drawer) {
    // stylelint-disable declaration-no-important
    top: 0 !important;
    width: 990px !important;

    .q-drawer__content {
      display: flex;
      flex-direction: column;
    }

    .form-label {
      margin-bottom: 16px;
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    ::v-deep(.q-drawer) {
      width: 100vw !important;
    }

    .form-input-wrapper {
      flex-direction: column;
      gap: 23px !important;
      align-items: flex-start !important;
    }
  }

  .form-wrapper {
    padding: 32px 24px;
    height: 100%;
  }

  .drawer-wrapper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    height: 63px;
  }

  .drawer-header {
    font-size: 24px;
    font-weight: 700;
    line-height: 31px;
    color: $mi-anthracite-800;
  }

  .separator {
    background-color: $mi-anthracite-50;
  }

  .drawer-description {
    padding: 16px 24px;
  }

  .drawer-description-header {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    margin: 0;
    font-family: "MAN Europe", sans-serif;
  }

  .drawer-description-text {
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }

  .form-input-wrapper {
    margin-bottom: 32px;
    gap: 47px;
    justify-content: flex-start;
    min-height: 134px;
    align-items: flex-start;
  }

  .job-setup-wrapper {
    width: 60%;
    display: flex;
    flex-direction: column;
  }

  .job-setup-bottom-wrapper {
    gap: 22px;
  }

  .inspector-bottom-input {
    width: 48%;
  }

  .confirm-btns-wrapper {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  .inspector-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100%;
  }

  .drawer-content {
    height: 100%;
  }

  // need to limit to only show the fist 7 PM
  ::v-deep(.q-menu .q-position-engine) {
    max-height: 340px !important;
  }

  .form-label {
    &__wrapper {
      width: 311px !important;
    }
  }

  .overlay {
    z-index: 2001;
  }
</style>
