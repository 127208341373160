<template>
  <div class="choices-combinations-container">
    <div
      v-for="(combination, index) in choicesCombinations"
      :key="index"
      class="q-mb-lg flex row items-end"
    >
      <choices-combinations-input
        :index="index"
        :combination="combination"
        :show-tooltip="true"
        :add-choices="addChoices"
        :remove-choice="removeChoice"
        :update-invalid-value="updateInvalidCombinationValue"
        :invalid-choices="invalidChoices"
        :choices-combinations-input-rules="choicesCombinationsRules"
      >
      </choices-combinations-input>

      <mi-btn
        v-if="!(index === 0 && choicesCombinations.length === 1)"
        class="q-ml-xs q-mt-xs q-pa-none"
        icon="minus-circle"
        icon-size="18px"
        icon-type
        fab
        flat
        @click="removeCombination(index)"
      ></mi-btn>
    </div>
    <div>
      <mi-btn
        v-show="true"
        :disable="disableAddBtn"
        icon="plus"
        icon-type
        icon-size="12px"
        outline
        @click="addCombination"
      >
        Add another combination
      </mi-btn>
    </div>
  </div>
</template>

<script>
  import { ref, watch } from 'vue'
  import { required } from '@/utils/validators'
  import ChoicesCombinationsInput from './ChoicesCombinationsInput.vue'

  export default {
    name: 'ChoicesCombinationsContainer',
    components: { ChoicesCombinationsInput },
    props: {
      invalidChoices: {
        type: Set,
        required: true
      }
    },
    emits: ['update:pre-conditions'],
    setup(props, { emit }) {
      const choicesCombinations = ref([{ preCondition: [], message: '', invalid: false }])
      const maxCombinations = ref(5)
      const disableAddBtn = ref(false)
      const choicesCombinationsRules = ref([required])

      const addChoices = (choices, combinationIndex) => {
        choicesCombinations.value[combinationIndex].preCondition = choices
      }

      const removeChoice = (choice, combinationIndex) => {
        choicesCombinations.value[combinationIndex].preCondition.splice(choice.index, 1)
      }

      const addCombination = () => {
        if (choicesCombinations.value.length >= maxCombinations.value) return
        choicesCombinations.value.push({ preCondition: [], message: '', invalid: false })
        if (choicesCombinations.value.length === maxCombinations.value) {
          disableAddBtn.value = true
        }
      }

      const removeCombination = index => {
        choicesCombinations.value.splice(index, 1)
        if (choicesCombinations.value.length < maxCombinations.value) {
          disableAddBtn.value = false
        }
      }

      const updateInvalidCombinationValue = (index, field, value) => {
        choicesCombinations.value[index][field] = value
      }

      const validateInvalidChoices = () => {
        choicesCombinations.value.map(setting => {
          const hasMatch = setting.preCondition?.some(item => props.invalidChoices.has(item))
          if (hasMatch) {
            setting.invalid = true
            setting.message = 'Non existing code(s) in product model. Enter only valid codes.'
          }
          return setting
        })
      }

      watch([choicesCombinations.value, props.invalidChoices], () => {
        validateInvalidChoices()
        emit('update:pre-conditions', { choicesCombinations: choicesCombinations.value })
      })

      return {
        choicesCombinations,
        updateInvalidCombinationValue,
        removeCombination,
        addCombination,
        removeChoice,
        addChoices,
        disableAddBtn,
        choicesCombinationsRules
      }
    }
  }
</script>

<style lang="scss" scoped>
  // stylelint-disable declaration-no-important
  .choices-combinations-container {
    background-color: $mi-white;
    width: 564px;
  }

  .q-btn {
    font-size: 14px;
  }

  .mi-btn.q-btn.disabled:hover {
    color: #d3d3d3 !important;
  }

  ::v-deep(.q-field) {
    margin-left: 0 !important;
  }

</style>
