<template>
  <mi-dialog
    :model-value="modelValue"
    class="table-display-dialog"
    :on-handle-close="resetToPreviousSelection"
    @update:model-value="emitUpdateModelValue"
  >
    <div class="table-display-dialog__header">
      <h3 class="text"> Table display </h3>
    </div>
    <div class="table-display-dialog__content">
      <p class="title">DEPARTMENTS</p>
      <mi-text-field
        v-model="departmentSearchString"
        class="search-field q-ml-auto"
        placeholder="Search department"
        hide-bottom-space
        outlined
      >
        <template #prepend>
          <mi-icon size="17px" name="search"></mi-icon>
        </template>
      </mi-text-field>
      <div class="departments-list">
        <div class="header flex align-center">
          <mi-checkbox
            :model-value="isAllSelected"
            color="accent"
            @click.stop="handleItemClick()"
          ></mi-checkbox>
          <span>All ({{ selectedDepartmentsCounter }})</span>
        </div>
        <mi-virtual-scroll
          ref="miVirtualScroll"
          class="departments-list__scroll-container items-center q-ma-md"
          :items="departmentsSearchList"
          virtual-scroll-item-size="46"
          virtual-scroll-slice-size="10"
        >
          <template #default="{ item }">
            <p>
              <mi-checkbox
                :model-value="isDepartmentSelected(item)"
                color="accent"
                @click="handleSubItemClick(item)"
              ></mi-checkbox>
              {{ item }}
            </p>
          </template>
        </mi-virtual-scroll>
      </div>
    </div>

    <template #actions>
      <mi-btn v-close-popup flat @click="resetToPreviousSelection()"> Cancel</mi-btn>
      <mi-btn class="q-mr-sm" color="accent" @click="applyFilterByDepartment()"> Apply </mi-btn>
    </template>
  </mi-dialog>
</template>

<script>
  import { getCurrentInstance, onBeforeMount, ref, watch } from 'vue'
  import { filterBySubstring } from '@/utils/filterListByString'
  import { getInspectionsDepartments } from '@/api/rest/inspector'
  import MiBtn from '../../packages/@mi-library/MiBtn/MiBtn.vue'
  import MiCheckbox from '../../packages/@mi-library/MiCheckbox/MiCheckbox.vue'

  export default {
    name: 'InspectorTableDisplayDialog',
    components: { MiCheckbox, MiBtn },
    props: {
      modelValue: {
        type: Boolean,
        required: true
      },
      fetchInspections: {
        type: Function,
        default: () => {}
      },
      handleHoover: {
        type: Function,
        required: true,
        default: () => {}
      }
    },
    emits: ['update:model-value', 'fetchInspections'],
    setup(props, { emit }) {
      const departmentSearchString = ref('')
      const departmentsList = ref([])
      const departmentsSearchList = ref([])
      const departmentsListSelected = ref([])
      const selectedDepartmentsCounter = ref(0)
      const isDialogOpen = ref(props.modelValue)
      const isAllSelected = ref(false)
      const { proxy } = getCurrentInstance()

      const switchAllSelectedCheckbox = () => {
        if (selectedDepartmentsCounter.value === departmentsList.value?.length) {
          isAllSelected.value = true
        }
        else if (selectedDepartmentsCounter.value === 0) {
          isAllSelected.value = false
        }
        else {
          isAllSelected.value = null
        }
      }

      const resetToPreviousSelection = () => {
        departmentsListSelected.value = proxy.$q.localStorage.getItem(
          process.env.VUE_APP_STORAGE_KEY_INSPECTOR_DEPARTMENTS
        ) || []
        selectedDepartmentsCounter.value = departmentsListSelected.value.length
        switchAllSelectedCheckbox()
        props.handleHoover(false)
      }

      const fetchDepartmentsList = async () => {
        try {
          const data = await getInspectionsDepartments()
          departmentsList.value = data.departments
          departmentsSearchList.value = data.departments
          resetToPreviousSelection()
        }
        catch (e) {
          console.error(e)
        }
      }

      const emitUpdateModelValue = (value = false) => {
        emit('update:model-value', value)
      }

      const applyFilterByDepartment = () => {
        proxy.$q.localStorage.remove(process.env.VUE_APP_STORAGE_KEY_INSPECTOR_DEPARTMENTS)
        proxy.$q.localStorage.set(
          process.env.VUE_APP_STORAGE_KEY_INSPECTOR_DEPARTMENTS,
          departmentsListSelected.value
        )
        emitUpdateModelValue(false)
        props.fetchInspections()
      }

      const handleSubItemClick = department => {
        const departmentIndex = departmentsListSelected.value.indexOf(department)
        if (departmentIndex === -1) {
          departmentsListSelected.value.push(department)
        }
        else {
          departmentsListSelected.value.splice(departmentIndex, 1)
        }

        selectedDepartmentsCounter.value = departmentsListSelected.value.length
        switchAllSelectedCheckbox()
      }

      const handleItemClick = () => {
        if (isAllSelected.value) {
          departmentsListSelected.value = []
        }
        else {
          departmentsListSelected.value = departmentsList.value.slice()
        }
        selectedDepartmentsCounter.value = departmentsListSelected.value.length
        isAllSelected.value = !isAllSelected.value
      }

      const isDepartmentSelected = department => departmentsListSelected.value.includes(department)

      watch(
        () => departmentSearchString.value,
        newValue => {
          departmentsSearchList.value = filterBySubstring(departmentsList.value, newValue)
        }
      )

      onBeforeMount(() => {
        fetchDepartmentsList()
      })

      return {
        emitUpdateModelValue,
        applyFilterByDepartment,
        departmentsList,
        selectedDepartmentsCounter,
        isAllSelected,
        isDepartmentSelected,
        handleSubItemClick,
        handleItemClick,
        departmentsListSelected,
        departmentSearchString,
        departmentsSearchList,
        isDialogOpen,
        resetToPreviousSelection
      }
    }
  }
</script>

<style lang="scss">
  .table-display-dialog {
    &__header {
      background-color: $mi-anthracite-50;

      .text {
        margin: 0;
        padding: 0 24px 16px;
      }
    }

    &__content {
      margin: 24px;

      .title {
        font-family: "MAN Europe Condensed", sans-serif;
        font-weight: 700;
        font-size: 18px;
        color: $mi-anthracite-800;
      }

      .departments-list {
        border: 1px solid #abb6c3;

        .header {
          padding: 16px 8px;
          border-bottom: 1px solid #abb6c3;
        }

        &__scroll-container {
          height: 355px;
          max-height: 355px;
          overflow-y: auto;
        }
      }
    }

    .q-dialog__inner > .mi-card {
      width: 924px;
    }

    &__body-paragraph {
      line-height: 24px;
    }

    .mi-card__section--close-dialog {
      background-color: $mi-anthracite-50;
      height: 20px;

      .q-btn {
        padding: 0;
        margin: 16px 24px;
        z-index: 1;
      }
    }

    .q-card__section {
      padding: 0;
    }
  }

  @media (max-width: $mi-responsive-medium-width) {
    .table-display-dialog {
      .q-dialog__inner > .mi-card {
        width: 924px;
        max-width: 924px;
      }
    }
  }

  @media (min-width: 600px) {
    .table-display-dialog {
      .q-dialog__inner--minimized > div {
        max-width: 924px;
        min-height: 665px;
      }
    }
  }
</style>
