<template>
  <div class="inspector-container">
    <div class="row inspector-container__filter-wrapper">
      <inspector-filter-bar
        :has-inspections="table?.rows?.length > 0 || isMineOnlyFilterSelected"
        :handle-hoover="handleHoover"
        :hoover="hoover"
        :handle-mine-only-filter="handleMineOnlyFilter"
        :are-inspections-loading="table?.loading"
        :handle-refresh-btn-change="fetchInspections"
        :is-mine-only-filter-selected="isMineOnlyFilterSelected"
        @handle-open-dialog="emitUpdateModelValue(true)"
      ></inspector-filter-bar>
    </div>
    <div class="inspector-container__content">
      <inspector-table
        :pagination="pagination"
        :table="table"
        :fetch-inspections="fetchInspections"
        :on-change-sort-table-direction="tableCallbacks.onChangeSortTableDirection"
        :on-change-sort-table-column-visibility="tableCallbacks.onChangeSortTableColumnVisibility"
        :handle-pagination-change="handlePaginationChange"
      ></inspector-table>
      <inspector-table-display-dialog
        :model-value="isTableDisplayDialogVisible"
        :fetch-inspections="fetchInspections"
        :handle-hoover="handleHoover"
        @update:model-value="emitUpdateModelValue"
      ></inspector-table-display-dialog>
    </div>
  </div>
</template>

<script>
  import { computed, getCurrentInstance, ref, watch } from 'vue'
  import { getInspections } from '@/api/rest/inspector'
  import { usePagination } from '@/composables/usePagination'
  import { INSPECTOR_TABLE_COLUMNS, INSPECTOR_TABLE_SORT_MAPPING } from '@/constants/inspector'
  import { useTable } from '@/composables/useTable'
  import { useStore } from 'vuex'
  import InspectorTable from './InspectorTable.vue'
  import InspectorFilterBar from './InspectorFilterBar.vue'
  import InspectorTableDisplayDialog from './InspectorTableDisplayDialog.vue'

  export default {
    name: 'InspectorContainer',
    components: { InspectorTableDisplayDialog, InspectorTable, InspectorFilterBar },
    setup() {
      const isTableDisplayDialogVisible = ref(false)
      const { pagination, handleChange: handlePaginationChange } = usePagination({ pageSize: 12 })
      const { table, callbacks: tableCallbacks } = useTable(INSPECTOR_TABLE_COLUMNS)
      const { proxy } = getCurrentInstance()
      const hoover = ref(false)
      const isMineOnlyFilterSelected = ref(false)
      const store = useStore()
      const user = computed(() => store.state.user)

      const emitUpdateModelValue = (value = false) => {
        isTableDisplayDialogVisible.value = value
      }

      const handleHoover = state => {
        hoover.value = state
      }

      const buildUrlSearchParams = () => {
        const departments = proxy.$q.localStorage.getItem(process.env.VUE_APP_STORAGE_KEY_INSPECTOR_DEPARTMENTS) || []
        const params = new URLSearchParams()
        params.append('page', pagination.currentPage - 1)
        params.append('pageSize', pagination.pageSize)
        departments.length > 0 ? params.append('departments', departments.join(',')) : ''
        isMineOnlyFilterSelected.value ? params.append('users', user.value.username) : ''
        params.append('sort',
                      table.sort?.sortBy
                        ? `${ INSPECTOR_TABLE_SORT_MAPPING[table.sort.sortBy] },${ table.sort.sortDirection }`
                        : 'startDate,desc')

        return params.toString()
      }

      const fetchInspections = async () => {
        const params = buildUrlSearchParams()
        const fetchInspectionsPromise = () => getInspections(params)
        const data = await tableCallbacks.fetchTableData(fetchInspectionsPromise)
        pagination.totalPages = data?.totalPages || 1
        pagination.totalElements = data?.totalElements || 0
      }

      const handleMineOnlyFilter = () => {
        isMineOnlyFilterSelected.value = !isMineOnlyFilterSelected.value
        if (pagination.currentPage !== 1 && isMineOnlyFilterSelected.value) {
          pagination.currentPage = 1
        }
        else {
          fetchInspections()
        }
      }

      watch(() => table.sort, () => {
        fetchInspections()
      })

      return {
        isTableDisplayDialogVisible,
        emitUpdateModelValue,
        fetchInspections,
        table,
        pagination,
        handlePaginationChange,
        hoover,
        handleHoover,
        isMineOnlyFilterSelected,
        handleMineOnlyFilter,
        tableCallbacks
      }
    }
  }
</script>

<style lang="scss" scoped>
  .inspector-container {
    background-color: $mi-anthracite-50;
    height: 100vh;

    &__filter-wrapper {
      position: fixed;
      z-index: 100;
      background-color: $mi-white;
      height: 3.75rem;
      width: 100%;
      padding: 8px 24px;
      align-items: center;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .24);
    }
  }
</style>
