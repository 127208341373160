import { removePrefixFromPP, sortDate } from '../components/saved-tables/utils'
import formatDate from '../utils/formatDate'

export const MAX_ELEMENTS = 10

export const INSPECTOR_TABLE_COLUMNS = [
  {
    name: 'name',
    label: 'Name',
    align: 'left',
    field: 'name',
    style: 'min-width: 240px; word-break: break-word; capitalize',
    showSortElement: false,
    sortable: true,
    sortDirection: 'ASC'
  },
  {
    name: 'elements',
    label: 'Elements',
    align: 'left',
    field: 'id',
    showSortElement: false,
    sortable: true,
    sortDirection: 'ASC'
  },
  {
    name: 'pmName',
    label: 'Product model',
    align: 'left',
    field: 'businessName',
    style: 'min-width: 176px; max-width: 256px; capitalize',
    showSortElement: false,
    sortable: true,
    sortDirection: 'ASC'
  },
  {
    name: 'planningPeriod',
    align: 'left',
    label: 'Planning period',
    field: ({ checkRange } = {}) => removePrefixFromPP(checkRange.from),
    showSortElement: false,
    sortable: true,
    sortDirection: 'ASC',
    style: 'min-width: 176px; max-width: 256px; text-transform: capitalize'
  },
  {
    name: 'creator',
    label: 'Creator',
    align: 'left',
    field: 'user',
    style: 'min-width: 80px; max-width: 160px',
    showSortElement: false,
    sortable: true,
    sortDirection: 'ASC'
  },
  {
    name: 'department',
    label: 'Dept.',
    align: 'left',
    field: 'department',
    style: 'min-width: 80px; max-width: 160px;',
    showSortElement: false,
    sortable: true,
    sortDirection: 'ASC'
  },
  {
    name: 'status',
    label: 'Status',
    align: 'left',
    field: 'status',
    style: 'min-width: 122px; max-width: 160px',
    showSortElement: false,
    sortable: true,
    sortDirection: 'ASC'
  },
  {
    name: 'createdDate',
    label: 'Date (start - end)',
    align: 'left',
    showSortElement: true,
    sortable: true,
    sortDirection: 'DESC',
    field: ({ startDate, endDate } = {}) => ({ startDate, endDate }),
    sort: (a, b) => sortDate(a, b),
    format: ({ startDate, endDate } = {}) => `
    ${ formatDate(startDate) } - ${ endDate ? formatDate(endDate) : '' }`,
    style: 'min-width: 160px; max-width: max-content'
  },
  {
    name: 'actions',
    label: '',
    align: 'right',
    field: 'actions',
    sortable: false
  }
]

export const INSPECTOR_TABLE_STATUS = Object.freeze({
  FAILED: { name: 'Failed', color: 'error' },
  IN_PROGRESS: { name: 'In progress', color: 'info' },
  READY: { name: 'Ready', color: 'success' },
  READY_WARNING: { name: 'Ready', color: 'warning' }
})

export const INSPECTOR_TABLE_SORT_MAPPING = Object.freeze({
  name: 'name',
  elements: 'elements',
  pmName: 'businessName',
  planningPeriod: 'checkRange',
  creator: 'user',
  department: 'department',
  status: 'statusName',
  createdDate: 'startDate'
})

export const READY_STATUS_KEY = 'READY'
