<template>
  <div class="inspector-table">
    <div v-if="table.rows">
      <mi-table
        ref="miTable"
        :columns="table.columns"
        :rows="table.rows"
        :loading="table.loading"
        class="q-px-lg q-pb-lg"
        :custom-header="true"
        :on-change-sort-table-direction="onChangeSortTableDirection"
        :on-change-sort-table-column-visibility="onChangeSortTableColumnVisibility"
        column-sort-order="da"
        loading-label="Loading results. Please wait ..."
        row-key="id"
        hide-pagination
        sticky-header
        striped
        wrap-cells
      >
        <template #top-left>
          <div class="inspector-table__header">
            <div>
              <div class="row items-center">
                <p class="q-table__title q-mb-sm">Inspector</p>
                <mi-icon
                  name="info-circle-outline"
                  size="14px"
                  class="q-ml-sm cursor-pointer"
                  @click="() => isDialogVisible = true"
                >
                </mi-icon>
                <inspector-explanation-dialog
                  :model-value="isDialogVisible"
                  :handle-close-dialog="() => isDialogVisible = false"
                ></inspector-explanation-dialog>
              </div>
              <span class="inspector-table__header-counter q-mb-sm">
                {{ pagination.totalElements }} results found
              </span>
            </div>
          </div>
        </template>
        <template #top-right>
          <div class="inspector-table__header">
            <mi-btn
              color="accent"
              icon="plus"
              icon-size="12px"
              @click="handleShow"
            >
              New Inspection
            </mi-btn>
          </div>
        </template>
        <template #body-cell-elements="{ value }">
          <td class="elements-column">
            <mi-tooltip
              v-if="findElements(value).length > MAX_ELEMENTS"
              max-width="220px"
              :center-text="true"
            >
              {{ formatTooltipElements(value) }}
            </mi-tooltip>
            {{ formatElements(value) }}
          </td>
        </template>
        <template #body-cell-status="{ value }">
          <mi-td>
            <mi-tooltip
              v-if="value ?
                getStatus(value).color === INSPECTOR_TABLE_STATUS.READY_WARNING.color
                : false"
              max-width="220px"
              :center-text="true"
            >
              Result may not be full since some preconditions may have failed.
            </mi-tooltip>
            <man-tag v-if="value" :variant="getStatus(value).color" size="m">
              {{ getStatus(value).name }}
            </man-tag>
          </mi-td>
        </template>
        <template v-if="!table.loading" #no-data>
          <div class="no-data-message">
            <p class="q-mb-sm">No result found</p>
            <p class="no-data-message__text">
              Press “New inspection” to start a process.
            </p>
          </div>
        </template>
        <template #body-cell-actions="{ row }">
          <div class="flex actions-wrapper">
            <mi-btn
              class="action-btn"
              color="transparent"
              @click.stop
            >
              <q-icon size="small">
                <img src="@/assets/images/dots_vertical.svg" alt="Saved tables actions" />
              </q-icon>
              <mi-menu>
                <q-list class="actions-column__list">
                  <q-item v-close-popup clickable @click="onDropdownDeleteClick(row.name, row.id)">
                    <q-item-section>
                      <q-item-label>Delete</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </mi-menu>
            </mi-btn>
            <mi-btn
              v-if="row?.status?.name === 'READY'"
              class="action-btn"
              color="transparent"
              @click="handleExportData(row.id)"
            >
              <q-icon size="small">
                <img src="@/assets/images/rule-validation/header-icons/download.svg" alt="Saved tables actions" />
              </q-icon>
              <mi-tooltip>
                Export data
              </mi-tooltip>
            </mi-btn>
          </div>
        </template>
      </mi-table>
    </div>
    <div class="inspector-table__pagination">
      <mi-pagination
        :model-value="pagination.currentPage"
        :max="pagination.totalPages"
        :max-pages="pagination.maxNavPage"
        size="14px"
        boundary-links
        direction-links
        boundary-numbers
        ellipses
        @update:model-value="handlePaginationChange"
      ></mi-pagination>
    </div>
  </div>
  <inspector-drawer
    :is-drawer-shown="isDrawerShown"
    :handle-hide="handleHide"
    @refresh-inspections="fetchInspections"
  >
  </inspector-drawer>
  <delete-inspection
    :delete-modal="deleteModal"
    :row-info="rowInfo"
    :handle-close-delete-modal="handleCloseDeleteModal"
    :handle-delete-modal="handleDeleteModal"
  ></delete-inspection>
</template>

<script>
  import { computed, onBeforeMount, ref, watch } from 'vue'
  import {
    MAX_ELEMENTS,
    INSPECTOR_TABLE_STATUS,
    READY_STATUS_KEY
  } from '@/constants/inspector'
  import {
    exportInspection,
    deleteInspection
  } from '@/api/rest/inspector'
  import MiTable from '@/packages/@mi-library/MiTable/MiTable.vue'
  import MiPagination from '@/packages/@mi-library/MiPagination/MiPagination.vue'
  import MiBtn from '@/packages/@mi-library/MiBtn/MiBtn.vue'
  import MiIcon from '@/packages/@mi-library/MiIcon/MiIcon.vue'
  import DeleteInspection from '@/components/inspector/DeleteInspection.vue'
  import InspectorDrawer from '@/components/inspector/InspectorDrawer.vue'
  import InspectorExplanationDialog from './InspectorExplanationDialog.vue'
  import MiTooltip from '../../packages/@mi-library/MiTooltip/MiTooltip.vue'
  import { exportToExcel } from '../../utils/exportExcel'

  export default {
    name: 'InspectorTable',
    components: {
      MiTooltip,
      InspectorDrawer,
      MiIcon,
      MiBtn,
      MiPagination,
      MiTable,
      InspectorExplanationDialog,
      DeleteInspection
    },
    props: {
      table: {
        type: Object,
        default: () => ({})
      },
      fetchInspections: {
        type: Function,
        default: () => {}
      },
      pagination: {
        type: Object,
        default: () => ({})
      },
      handlePaginationChange: {
        type: Function,
        default: () => {}
      },
      onChangeSortTableDirection: {
        type: Function,
        default: () => {}
      },
      onChangeSortTableColumnVisibility: {
        type: Function,
        default: () => {}
      }
    },
    setup(props) {
      const elementsMap = computed(() => {
        const map = new Map()
        props.table.rows.forEach(item => {
          map.set(item.id, item.elements.split(', '))
        })
        return map
      })

      const deleteModal = ref(false)
      const rowInfo = {
        name: '',
        id: ''
      }

      const isDialogVisible = ref(false)
      const isDrawerShown = ref(false)

      const handleShow = () => {
        isDrawerShown.value = true
      }

      const handleHide = () => {
        isDrawerShown.value = false
      }

      const findElements = id => elementsMap.value.get(id) || []

      const formatTooltipElements = id => {
        const elements = findElements(id)
        return elements.join(', ')
      }

      const formatElements = id => {
        const elements = findElements(id)
        if (elements.length > MAX_ELEMENTS) {
          return `${ elements.slice(0, MAX_ELEMENTS).join(', ') }, ...`
        }
        return elements.join(', ')
      }

      const getStatus = ({ name, summary }) => {
        if (name === READY_STATUS_KEY) {
          if (summary.failure > 0) return INSPECTOR_TABLE_STATUS.READY_WARNING
          return summary.success === summary.setup
            ? INSPECTOR_TABLE_STATUS.READY
            : INSPECTOR_TABLE_STATUS.IN_PROGRESS
        }
        return INSPECTOR_TABLE_STATUS[name]
      }

      const handleExportData = async rowId => {
        try {
          const result = await exportInspection(rowId)
          exportToExcel(result)
        }
        catch (error) {
          console.error('Error exporting file:', error)
        }
      }

      onBeforeMount(async () => {
        await props.fetchInspections()
      })

      watch(
        () => props.pagination.currentPage,
        async () => {
          await props.fetchInspections()
        }
      )

      const onDropdownDeleteClick = (rowName, rowID) => {
        deleteModal.value = true
        rowInfo.name = rowName
        rowInfo.id = rowID
      }
      const handleCloseDeleteModal = () => {
        deleteModal.value = false
      }

      const handleDeleteModal = async () => {
        await deleteInspection(rowInfo.id)
        handleCloseDeleteModal()
        await props.fetchInspections()
      }

      return {
        getStatus,
        handleShow,
        isDrawerShown,
        handleHide,
        isDialogVisible,
        INSPECTOR_TABLE_STATUS,
        formatElements,
        findElements,
        formatTooltipElements,
        MAX_ELEMENTS,
        onDropdownDeleteClick,
        handleExportData,
        deleteModal,
        rowInfo,
        handleCloseDeleteModal,
        handleDeleteModal
      }
    }
  }
</script>

<style lang="scss" scoped>
  .inspector-table {
    min-height: calc(100vh - 70px);
    padding: 24px;
    position: relative;
    top: 65px;

    .elements-column {
      font-family: $mi-typography-font-family;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      max-width: 250px;
    }

    .q-table__container {
      min-height: calc(100vh - 225px);
    }

    .q-table__title {
      font-family: $mi-typography-font-family-condensed;
    }

    &__pagination {
      display: flex;
      justify-content: center;
      padding: 8px 0 16px;
      background-color: $mi-white;
    }

    &__header-counter {
      color: #5b6f85;
      font-size: 12px;
      font-style: normal;
      font-family: $mi-typography-font-family-condensed;
      font-weight: 700;
      line-height: 130%;
    }

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    ::v-deep(.q-table tr) {
      height: 48px;
    }
  }

  .mi-table-wrapper .q-table__bottom--nodata {
    align-items: center;

    .no-data-message {
      font-size: 14px;
      font-family: $mi-typography-font-family;
      font-weight: 700;
      color: $mi-anthracite-800;
      display: flex;
      flex-direction: column;
      align-items: center;

      &__text {
        font-weight: 400;
      }
    }
  }

  .actions-column {
    display: flex;
    justify-items: center;

    &__list {
      width: 220px;
    }
  }

  .actions-wrapper {
    width: max-content;
  }
</style>
